<template>
    <section class="emsculpt-comparison py-5 overflow-hidden">
        <div class="container">
            <div class="d-none d-md-block mb-5"
                 data-aos="flip-up"
                 data-aos-duration="1000">
                <h1 class="text-center display-4 font-weight-bold mb-0">
                    ULTRA FEMME 360&trade;
                </h1>
                <h4 class="text-center mb-0">
                    vs
                </h4>
                <h1 class="text-center mb-0">
                    其他療程
                </h1>
            </div>

            <div class="d-block d-md-none mb-5"
                 data-aos="flip-up"
                 data-aos-duration="1000">
                <h2 class="text-center font-weight-bold mb-0">
                    ULTRA FEMME 360&trade;
                </h2>
                <h4 class="text-center mb-0">
                    vs
                </h4>
                <h2 class="text-center mb-0">
                    其他療程
                </h2>
            </div>

            <div class="row text-dark mb-4">
                <div class="col-lg-6 px-4 mb-3">
                    <div class="card hightlighted rounded-lg border-0 h-100"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <div class="card-body p-4">
                            <h4 class="card-title text-center  font-weight-bolder mb-3 py-4 text-white">
                                ULTRA FEMME 360&trade;私密超頻收陰機
                            </h4>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item border-top-0">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                原理
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                超聲波結合單極射頻 以熱能刺激膠原增生
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                風險
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                一次性即棄探頭 非入侵性 零創傷
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                感覺
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                無痛 微溫
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                需時
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                8分鐘
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                康復
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                無復原期 可即時活動
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                安全
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                獲美國FDA 歐盟CE認證
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-4 pr-2">
                                            <h5 class="font-noto-serif-tc text-heading mb-0">
                                                覆蓋
                                            </h5>
                                        </div>
                                        <div class="col-8">
                                            <p class="mb-0">
                                                360°全內陰 零盲點
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="position-relative col-lg-6 px-4 mb-3">
                    <div class="position-absolute btn-swiper-wrapper left d-flex align-items-center"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <button class="btn-swiper text-white display-4 h1"
                                v-on:click="slidePrev">
                            <font-awesome-icon icon="caret-left"></font-awesome-icon>
                        </button>
                    </div>

                    <div class="d-flex h-100"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <swiper class="flex-grow-1"
                                ref="others"
                                v-bind:options="swiperOptions">
                            <swiper-slide class="h-100">
                                <div class="card rounded-lg border-0 h-100">
                                    <div class="card-body p-4">
                                        <h4 class="card-title text-center text-white mb-3 py-4">
                                            EMSELLA™ 幸福椅
                                        </h4>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-top-0">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            原理
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            專利HIFEM™技術 帶動盆底肌持續收縮提升
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            風險
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            非入侵性、無創
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            感覺
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            無痛 私密部位肌肉持續收緊
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            需時
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            28分鐘
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            康復
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            無復原期 可即時活動
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            安全
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            獲美國FDA及歐盟CE認證
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            覆蓋
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            沒有任何覆蓋
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide class="h-100">
                                <div class="card rounded-lg border-0 h-100">
                                    <div class="card-body p-4">
                                        <h4 class="card-title text-center text-white mb-3 py-4">
                                            Laser CO2激光收陰
                                        </h4>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-top-0">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            原理
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            只能刺激淺層結締組織
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            風險
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            探頭重複使用 易感染婦科病
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            感覺
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            痛楚
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            需時
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            30分鐘
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            康復
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            最少2星期
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            安全
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            消毒問題
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            覆蓋
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            用固定器 有1/3盲點
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide class="h-100">
                                <div class="card rounded-lg border-0 h-100">
                                    <div class="card-body p-4">
                                        <h4 class="card-title text-center text-white mb-3 py-4">
                                            縮陰手術
                                        </h4>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-top-0">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            原理
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            將鬆弛的黏膜及其下層肌肉部分切除 再重新縫合
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            風險
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            需全身麻醉
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            感覺
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            痛楚
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            需時
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            2小時
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            康復
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            最少4星期
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            安全
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            留有疤痕
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="row no-gutters">
                                                    <div class="col-4 pr-2">
                                                        <h5 class="font-noto-serif-tc text-heading mb-0">
                                                            覆蓋
                                                        </h5>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="mb-0">
                                                            只能收窄陰道 不能增加膠原及彈性
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>

                    <div class="position-absolute btn-swiper-wrapper right d-flex align-items-center"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <button class="btn-swiper text-white display-4 h1"
                                v-on:click="slideNext">
                            <font-awesome-icon icon="caret-right"></font-awesome-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import "swiper/css/swiper.min.css";
    import {
        Swiper,
        SwiperSlide,
    } from "vue-awesome-swiper";

    export default {
        name: "IntimaComparison",
        components: {
            Swiper,
            SwiperSlide,
        },
        data () {
            return {
                "swiperOptions": {
                    "loop": true,
                    "effect": "fade",
                    "slidesPerView": 1,
                    "spaceBetween": 100,
                },
            };
        },
        computed: {
            swiperInstance () {
                if (this.$refs.others) {
                    return this.$refs.others.$swiper;
                }
                return {};
            },
        },
        methods: {
            slidePrev () {
                this.swiperInstance.slidePrev();
            },
            slideNext () {
                this.swiperInstance.slideNext();
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";
    @import "./variables.scss";

    .emsculpt-comparison {
        background-image: url($background-alt);
        background-color: $background-color-alt;
        color: $text-color;

        .card {
            .card-title {
                background-color: rgba(148, 148, 148, 1);
            }

            &.hightlighted {
                .card-title {
                    background-color: $heading-color;
                }

                .list-group-item {
                    background-color: transparent;
                }
            }
        }

        .btn-swiper-wrapper {
            width: 33px;
            height: 100%;

            top: 0;

            &.left {
                left: -7.5px;
            }

            &.right {
                right: -7.5px;
            }

            @include media-breakpoint-up(sm) {
                &.left {
                    left: -15px;
                }

                &.right {
                    right: -15px;
                }
            }

            & > .btn-swiper {
                background: none;
                outline: none;
                border: none;

                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0 ,0.25));
                z-index: 9;
            }
        }

        .text-heading {
            color: $heading-color;
        }

        .rounded-lg {
            border-radius: 15px !important;
        }
    }
</style>
